import React from "react"
import classNames from "classnames"

import FeaturesGallery, {
	FEATURE_GALLERY_DIRECTION,
} from "../../../components/_V2/FeaturesGallery"

import styles from "./styles.module.scss"
import { getData } from "./data"
import { useLocalizationContext } from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function UsageFeatures(props: Props) {
	const localizationContext = useLocalizationContext();

	return (
		<section className={classNames(styles.section, props.className)}>
			<FeaturesGallery
				key={localizationContext.locale}
				theme={"cyan-custom-backdrop"}
				className={styles.featuresGallery}
				features={getData(localizationContext.locale).features}
				direction={FEATURE_GALLERY_DIRECTION.REVERSE}
			/>
		</section>
	)
}
