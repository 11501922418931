import classNames from "classnames"
import React, { useState } from "react"
import { Link } from "gatsby"

import styles from "./styles.module.scss"
import AdaptiveWrapText from "../AdaptiveWrapText"
import ConsultationCtaFormA from "../ConsultationCtaFormA"
import ArrowRightIconSm from "../../../assets/_V2/svg/ArrowRightIconSm"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

/*type contentSize = ('md' | 'tabletSm'| 'tabletXs')*/

interface Feature {
	category?: string

	title: string | JSX.Element
	tabletTitle?: string | JSX.Element
	mobileTitle?: string | JSX.Element

	description: string | JSX.Element

	quote?: string | JSX.Element

	img?: JSX.Element
	mediaContent?: JSX.Element

	reverse?: boolean
	className?: string

	actionLink?: string
	actionText?: string
	actionClass?: string

	additionalActionLink?: string
	additionalActionText?: string
	additionalActionClass?: string

	theme?: string
	backdrop?: JSX.Element
	simpleAction?: boolean
	bottomContent?: JSX.Element
	descClass?: string;
	contentClass?: string;
	/* contentSize?: contentSize*/
}

type itemGap = "md" | "none"

interface Props {
	className?: string
	data: Feature[]
	itemGap?: itemGap
	isDisableLocaleClass?: boolean
}

export default function SimpleFeatures(props: Props) {
	const localizationContext = useLocalizationContext();

	const itemGap = props.itemGap !== undefined ? props.itemGap : "md"
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	const RenderFeature = (item: Feature) => {
		return (
			<article
				key={String(item.title)}
				className={classNames(
					styles.features__item,
					styles.feature,
					item.theme ? styles[item.theme] : "",
					item.simpleAction ? [styles.simple_action] : "",
					{ [styles.featureReverse]: item.reverse },
					{ [styles.features__item_gap_none]: itemGap === "none" },
					item.className
				)}
			>
				{/* если есть свойство mediaContent - выводим его, иначе только img */}
				{item.mediaContent ? (
					<div className={styles.feature__mediaContent}>
						{item.mediaContent}
					</div>
				) : (
					<figure
						className={classNames(
							styles.feature__img,
							styles.feature__mediaContent
						)}
					>
						{item.img}

						{item.quote && (
							<q className={styles.feature__quote}>{item.quote}</q>
						)}
					</figure>
				)}

				<div className={classNames(styles.feature__content, item?.contentClass)}>
					{item.category && (
						<span className={styles.feature__category}>{item.category}</span>
					)}
					{/* <h2 className={styles.feature__title}>
                        <AdaptiveWrapText
                            desktop={item.title}
                            tablet={item.tabletTitle}
                            mobile={item.mobileTitle}
                        />
                    </h2> */}

					<AdaptiveWrapText
						desktop={item.title}
						tablet={item.tabletTitle}
						mobile={item.mobileTitle}
						titleClasses={classNames(styles.feature__title, {[styles.feature__title__kz]: localizationContext.locale !== 'ru-RU' && !props.isDisableLocaleClass})}
						noBreakWordsMobile
					>
						<h2>{item.title}</h2>
					</AdaptiveWrapText>

					{typeof item.description === "string" ? (
						<div
							className={classNames(styles.feature__description, {[styles.feature__description__kz]: localizationContext.locale !== 'ru-RU'})}
							dangerouslySetInnerHTML={{ __html: item.description }}
						/>
					) : (
						<div className={classNames(styles.feature__description, item?.descClass)}>
							{item.description}
						</div>
					)}

					{item.bottomContent && (
						<div className={styles.feature__bottomContent}>
							{item.bottomContent}
						</div>
					)}
					<div className={styles.buttonSection}>
					{item.actionText &&
						item.actionLink &&
						(item.actionLink === "#popup" ? (
							<a
								onClick={handleModalStatus}
								href={item.actionLink}
								data-name='button'
								className={classNames(styles.feature__btn, item.actionClass)}
							>
								{item.actionText}
							</a>
						) : (
							<Link
								to={item.actionLink}
								data-name='button'
								className={classNames(styles.feature__btn, item.actionClass)}
							>
								{item.actionText}

								<ArrowRightIconSm className={styles.feature__arrow} />
							</Link>
						))}

					{item.additionalActionText &&
						item.additionalActionLink &&
						(item.additionalActionLink === "#popup" ? (
							<a
								onClick={handleModalStatus}
								href={item.additionalActionLink}
								data-name='button'
								className={classNames(styles.feature__btn, item.additionalActionClass)}
							>
								{item.additionalActionText}
							</a>
						) : (
							<Link
								to={item.additionalActionLink}
								data-name='button'
								target={'_blank'}
								className={classNames(styles.feature__btn, item.additionalActionClass)}
							>
								{item.additionalActionText}

								<ArrowRightIconSm className={styles.feature__arrow} />
							</Link>
						))}
					</div>
				</div>

				{item.backdrop}
			</article>
		)
	}

	return (
		<section className={classNames(styles.features, props.className)}>
			{props.data.map(item => RenderFeature(item))}
			<ConsultationCtaFormA isOpen={isModalOpen} onClose={handleModalStatus} />
		</section>
	)
}
