import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Анализировать",
					desktopTitle: "Всё под контролем",
					desktopContent:
						"Узнай, кто из поваров задерживает заказы. Отчёт покажет скорость приготовления блюд: для всей смены или по конкретному сотруднику",
					detailLink: pagesLinks.reports,
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/kds-settings-1_kz.webp"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Распределять нагрузку",
					desktopTitle: "Никакой путаницы \nв пиковые часы",
					desktopContent:
						"Настрой отображение заказов так, чтобы каждый видел только свои заказы — например, горячие блюда не прилетали в холодный цех — и повара не путались",
					detailLink: pagesLinks.registration,
					detailText: "Начать бесплатно",
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/kds-settings-2.webp"
							alt="кухонный дисплей для кафе"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Установка",
					desktopTitle: "Быстрый запуск",
					desktopContent:
						"Загрузи приложение из Google Play или App Store — работает на iPad, планшетах Android и телевизорах на платформе Android TV",
					detailLink: pagesLinks.registration,
					detailText: "Начать бесплатно",
					mediaContent: (
						<StaticImage
							className={mediaContent.image3}
							src="./assets/kds-settings-3.webp"
							alt="установка кухонного дисплея"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Талдау",
					desktopTitle: "Барлығы бақылауда",
					desktopContent:
						"Аспаптардың қайсысы тапсырыстарды кідіртіп тұрғанын біліңіз. Есеп тағамдарды дайындау жылдамдығын көрсетеді: бүкіл ауысым үшін немесе нақты қызметкер бойынша",
					detailLink: pagesLinks.reports,
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/kds-settings-1_kz.webp"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Жүктемені бөлу",
					desktopTitle: "Уақыт қызып тұрған\nезде ешқандай шатасу жоқ",
					desktopContent:
						"Әркім тек өз тапсырыстарын көріп тұратындай етіп - мысалы, ыстық тағамдар суых цехке келмейтіндей - және аспаздар шатаспайтында етіп тапсырыстардың көрсетілуін теңшеңіз",
					detailLink: pagesLinks.registration,
					detailText: "Тегін бастау",
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/kds-settings-2.webp"
							alt="кухонный дисплей для кафе"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Орнату",
					desktopTitle: "Жылдам іске қосу",
					desktopContent:
						"Google Play-ден немесе App Store-дан қосымшаны жүктеп алыңыз — iPad-та, Android планшеттерінде және Android TV платформасындағы теледидарларда жұмыс істейді",
					detailLink: pagesLinks.registration,
					detailText: "Тегін бастау",
					mediaContent: (
						<StaticImage
							className={mediaContent.image3}
							src="./assets/kds-settings-3.webp"
							alt="установка кухонного дисплея"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Анализировать",
					desktopTitle: "Анализируй \nи ускоряйся",
					desktopContent:
						"Узнай, кто из поваров задерживает заказы. Отчёт покажет скорость приготовления блюд: для всей смены или по конкретному сотруднику",
					detailLink: pagesLinks.reports,
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/kds-settings-1_by.webp"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Распределять нагрузку",
					desktopTitle: "Никакой путаницы \nв пиковые часы",
					desktopContent:
						"Настрой отображение заказов так, чтобы каждый видел только свои заказы — например, горячие блюда не прилетали в холодный цех — и повара не путались",
					detailLink: pagesLinks.registration,
					detailText: "Начать бесплатно",
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/kds-settings-2.webp"
							alt="кухонный дисплей для кафе"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Установка",
					desktopTitle: "Быстрый старт",
					desktopContent:
						"Загрузи приложение из Google Play или App Store — работает на iPad, планшетах Android и телевизорах на платформе Android TV",
					detailLink: pagesLinks.registration,
					detailText: "Начать бесплатно",
					mediaContent: (
						<StaticImage
							className={mediaContent.image3}
							src="./assets/kds-settings-3.webp"
							alt="установка кухонного дисплея"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
			],
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 490,
				tabName: "Анализировать",
				desktopTitle: "Всё под контролем",
				desktopContent:
					"Узнай, кто из поваров задерживает заказы. Отчёт покажет скорость приготовления блюд: для всей смены или по конкретному сотруднику",
				detailLink: pagesLinks.reports,
				mediaContent: (
					<StaticImage
						className={mediaContent.image1}
						src="./assets/kds-settings-1.webp"
						alt="отчеты по работе поваров"
						objectFit="contain"
						placeholder="blurred"
						quality={100}
					/>
				),
			},
			{
				desktopContentWidth: 500,
				tabName: "Распределять нагрузку",
				desktopTitle: "Пиковые часы не страшны",
				desktopContent:
					"Настрой отображение заказов так, чтобы каждый видел только свои заказы — например, горячие блюда не прилетали в холодный цех — и повара не путались",
				detailLink: pagesLinks.registration,
				detailText: "Начать бесплатно",
				mediaContent: (
					<StaticImage
						className={mediaContent.image2}
						src="./assets/kds-settings-2.webp"
						alt="кухонный дисплей для кафе"
						objectFit="contain"
						placeholder="blurred"
						quality={100}
					/>
				),
			},
			{
				desktopContentWidth: 500,
				tabName: "Установка",
				desktopTitle: "Быстрый старт",
				desktopContent:
					"Загрузи приложение из Google Play или App Store — работает на iPad, планшетах Android и телевизорах на платформе Android TV",
				detailLink: pagesLinks.registration,
				detailText: "Начать бесплатно",
				mediaContent: (
					<StaticImage
						className={mediaContent.image3}
						src="./assets/kds-settings-3.webp"
						alt="установка кухонного дисплея"
						objectFit="contain"
						placeholder="blurred"
						quality={100}
					/>
				),
			},
		],
	}
}
