import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Locales } from "../../../localization/types";

export const getAdvantages = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return [
			{
				img: (
					<StaticImage
						src="./assets/kds-advantages-1.png"
						alt="Оставь бумажки конкурентам"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Оставь бумажки конкурентам",
				description:
					"Забудь о&nbsp;расходниках: приложение полностью заменит принтер чеков для кухни&nbsp;&mdash; то&nbsp;есть сэкономит десятки тысяч тенге.",
			},
			{
				img: (
					<StaticImage
						src="./assets/kds-advantages-2.png"
						alt="Отлаженный механизм"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Отлаженный механизм",
				description:
					"Дримтим в&nbsp;деле: официанты и&nbsp;кухня работают слаженно и&nbsp;быстро, а&nbsp;гость получает блюда в&nbsp;нужном порядке.",
			},
			{
				img: (
					<StaticImage
						src="./assets/kds-advantages-3.png"
						alt="Аналитика — наше всё"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Аналитика — наше всё",
				description:
					"Можно замерить скорость приготовления блюда : понадобится для организации доставки и&nbsp;контроля работы поваров.",
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				img: (
					<StaticImage
						src="./assets/kds-advantages-1.png"
						alt="Оставь бумажки конкурентам"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Қағаздарды бәсекелестерге қалдырыңыз",
				description:
					"Шығыс материалдары туралы ұмытыңыз: қосымша асүйге арналған чектер принтерін толықтай\nалмастырады - яғни ондаған мың теңге үнемдейді.",
			},
			{
				img: (
					<StaticImage
						src="./assets/kds-advantages-2.png"
						alt="Отлаженный механизм"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Ретке келтірілген механизм",
				description:
					"Дримтим қосылды: даяшылар мен асүй үйлесімді әрі тез жұмыс істейді, ал қонақ тағамдарды қажетті ретімен алады.",
			},
			{
				img: (
					<StaticImage
						src="./assets/kds-advantages-3.png"
						alt="Аналитика — наше всё"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Аналитика — біз үшін ең маңызды",
				description:
					"Тағамды дайындау жылдамдығын өлшеуге болады: жеткізуді ұйымдастыру және аспаздардың жұмысын бақылау үшін керек болады.",
			},
		]
	}

	if (locale === 'ru-BY') {
		return [
			{
				img: (
					<StaticImage
						src="./assets/kds-advantages-1.png"
						alt="Оставь бумажки конкурентам"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Оставь бумажки конкурентам",
				description:
					"Забудь о&nbsp;расходниках: приложение полностью заменит принтер чеков для кухни&nbsp;&mdash; то&nbsp;есть сэкономит десятки тысяч рублей.",
			},
			{
				img: (
					<StaticImage
						src="./assets/kds-advantages-2.png"
						alt="Командная игра"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Командная игра",
				description:
					"Дримтим в&nbsp;деле: официанты и&nbsp;кухня работают слаженно и&nbsp;быстро, а&nbsp;гость получает блюда в&nbsp;нужном порядке.",
			},
			{
				img: (
					<StaticImage
						src="./assets/kds-advantages-3.png"
						alt="Аналитика — наше всё"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Аналитика — наше всё",
				description:
					"Замерим скорость приготовления блюд: понадобится для организации&nbsp;доставки и&nbsp;поможет контролировать эффективность работы поваров.",
			},
		]
	}

	return [
		{
			img: (
				<StaticImage
					src="./assets/kds-advantages-1.png"
					alt="Работает на всех устройствах"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Никакой бумажной возни",
			description:
				"Забудь о&nbsp;расходниках: приложение полностью заменит принтер чеков для кухни&nbsp;&mdash; то&nbsp;есть сэкономит десятки тысяч рублей.",
		},
		{
			img: (
				<StaticImage
					src="./assets/kds-advantages-2.png"
					alt="Дорога ложка к обеду"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Дорога ложка к обеду",
			description:
				"Дримтим в&nbsp;деле: официанты и&nbsp;кухня работают слаженно и&nbsp;быстро, а&nbsp;гость получает блюда в&nbsp;нужном порядке.",
		},
		{
			img: (
				<StaticImage
					src="./assets/kds-advantages-3.png"
					alt="Аналитика — наше всё"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Аналитика — наше всё",
			description:
				"Можно замерить скорость приготовления блюда : понадобится для организации доставки и&nbsp;контроля работы поваров.",
		},
	]
}
