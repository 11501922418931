import * as React from "react"
import { SVGProps } from "react"

const ArrowRightIconSm = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="6"
		height="10"
		viewBox="0 0 6 10"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.542893 0.792893C0.933417 0.402369 1.56658 0.402369 1.95711 0.792893L5.45711 4.29289C5.84763 4.68342 5.84763 5.31658 5.45711 5.70711L1.95711 9.20711C1.56658 9.59763 0.933417 9.59763 0.542893 9.20711C0.152369 8.81658 0.152369 8.18342 0.542893 7.79289L3.33579 5L0.542893 2.20711C0.152369 1.81658 0.152369 1.18342 0.542893 0.792893Z"
		/>
	</svg>
)

export default ArrowRightIconSm
