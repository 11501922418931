import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types";


export const getFeaturesData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return [
			{
				category: "экономия и экологичность",
				title: "Выгоднее тикет‑принтера",
				description: (
					<p>
						Принтер и термобумага для чеков — ощутимые статьи расходов: хороший
						принтер стоит около 100&nbsp;000&nbsp;₸, а&nbsp;на&nbsp;бумагу уходит
						45&nbsp;000&nbsp;₸ в&nbsp;месяц. И&nbsp;это — только для кухни одного
						заведения.
					</p>
				),

				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/kds-feature-1.png"
						alt="замена тикет-принтера"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),

				reverse: true,
				className: styles.feature1,
				theme: "grayLight",
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				category: "үнемділік пен экологиялық",
				title: "Тикет-принтерден үнемдірек",
				description: (
					<p>
						Приентер мен чектерге арналған термоқағаз - сезілетін шығыстар баптары: жақсы принтер шамамен
						100 000 ₸ тұрады, ал қағазға айын 45 000 ₸ кетеді. Және бұл — тек бір мекеменің асүйі үшін ғана.
					</p>
				),

				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/kds-feature-1.png"
						alt="замена тикет-принтера"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),

				reverse: true,
				className: styles.feature1,
				theme: "grayLight",
			},
		]
	}

	if (locale === 'ru-BY') {
		return [
			{
				category: "экономия и экологичность",
				title: "Выгоднее тикет‑принтера",
				description: (
					<p>
						Принтер и термобумага для чеков — ощутимые статьи расходов: хороший
						принтер стоит около 650&nbsp;Р, а&nbsp;на&nbsp;бумагу уходит
						200&nbsp;Р в&nbsp;месяц. И&nbsp;это — только для кухни одного
						заведения.
					</p>
				),

				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/kds-feature-1.png"
						alt="замена тикет-принтера"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),

				reverse: true,
				className: styles.feature1,
				theme: "grayLight",
			},
		]
	}

	return [
		{
			category: "экономия и экологичность",
			title: "Выгоднее тикет‑принтера",
			description: (
				<p>
					Принтер и термобумага для чеков — ощутимые статьи расходов: хороший
					принтер стоит около 20&nbsp;000&nbsp;₽, а&nbsp;на&nbsp;бумагу уходит
					9&nbsp;000&nbsp;₽ в&nbsp;месяц. И&nbsp;это — только для кухни одного
					заведения.
				</p>
			),

			img: (
				<StaticImage
					className={styles.image1}
					src="./assets/kds-feature-1.png"
					alt="замена тикет-принтера"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),

			reverse: true,
			className: styles.feature1,
			theme: "grayLight",
		},
	]
}
