import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "В зале",
					desktopTitle: "Передай другому",
					desktopContent: (
						<p>
							Официант оставляет повару комментарии — например, пожелания гостя по
							степени прожарки стейка — и&nbsp;указывает порядок подачи блюд.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/kds-usage-1.png"
							alt="кухонный дисплей для ресторана"
							objectFit="contain"
							placeholder="blurred"
							quality={96}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "На кухне",
					desktopTitle: "Ни слова больше",
					desktopContent:
						"Повар открывает техкарту, чтобы свериться с рецептом и граммовкой для блюда. А таймер напомнит о следующем шаге.",
					mobileContent:
						"Повар открывает техкарту, чтобы свериться с рецептом и граммовкой для блюда. А таймер напомнит о следующем шаге.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/kds-usage-2.png"
							alt="техкарта на экране повара"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Из кухни в зал",
					desktopTitle: "Готово! Можно выносить",
					desktopContent: (
						<p>
							Как только повар даёт отмашку, на терминале официанта меняется статус
							заказа. Звуковое уведомление подскажет, что&nbsp;пора забирать блюдо.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image3}
							src="./assets/kds-usage-3_kz.webp"
							alt="уведомления на кассовом терминале"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		}
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "Залда",
					desktopTitle: "Басқаға жеткіз",
					desktopContent: (
						<p>
							Даяшы аспазға түсініктемелер қалдырады - мысалы, қонақтың стейктің қуыру дәрежесі бойынша
							қалауларын - және тағамдарды беру тәртібін көрсетеді.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/kds-usage-1.png"
							alt="кухонный дисплей для ресторана"
							objectFit="contain"
							placeholder="blurred"
							quality={96}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Асүйде",
					desktopTitle: "Артық сөздің керегі жоқ",
					desktopContent:
						"Аспаз рецептпен және тағамға арналған мөлшерлермен салыстырып тексеру үшін техкартаны ашады. Ал таймер келесі қадам туралы еске салады.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/kds-usage-2.png"
							alt="техкарта на экране повара"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Асүйден залға",
					desktopTitle: "Дайын! Шығаруға болады",
					desktopContent: (
						<p>
							Аспаз рұқсат бере салысымен, даяшының терминалында тапсырыстың мәртебесі өзгереді. Дыбыстық
							хабарлама тағамды әкету кезі келгенін айтады.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image3}
							src="./assets/kds-usage-3_kz.webp"
							alt="уведомления на кассовом терминале"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "В зале",
					desktopTitle: "Передай другому",
					desktopContent: (
						<p>
							Официант оставляет повару комментарии — например, пожелания гостя по
							степени прожарки стейка — и&nbsp;указывает порядок подачи блюд.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/kds-usage-1.png"
							alt="кухонный дисплей для ресторана"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "На кухне",
					desktopTitle: "Всегда знай, \nчто делать дальше",
					desktopContent:
						"Повар открывает техкарту, чтобы свериться с рецептом и граммовкой для блюда. А таймер напомнит о следующем шаге.",
					mobileContent:
						"Повар открывает техкарту, чтобы свериться с рецептом и граммовкой для блюда. А таймер напомнит о следующем шаге.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/kds-usage-2.png"
							alt="техкарта на экране повара"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Из кухни в зал",
					desktopTitle: "Готово! Можно выносить",
					desktopContent: (
						<p>
							Как только повар даёт отмашку, на терминале официанта меняется статус
							заказа. Звуковое уведомление подскажет, что&nbsp;пора забирать блюдо.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image3}
							src="./assets/kds-usage-3_by.webp"
							alt="уведомления на кассовом терминале"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 500,
				tabName: "В зале",
				desktopTitle: "Пас в одно касание",
				desktopContent: (
					<p>
						Официант оставляет повару комментарии — например, пожелания гостя по
						степени прожарки стейка — и&nbsp;указывает порядок подачи блюд.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image1}
						src="./assets/kds-usage-1.png"
						alt="кухонный дисплей для ресторана"
						objectFit="contain"
						placeholder="blurred"
						quality={96}
					/>
				),
			},
			{
				desktopContentWidth: 500,
				tabName: "На кухне",
				desktopTitle: "Ни слова больше",
				desktopContent:
					"Повар открывает техкарту, чтобы свериться с рецептом и граммовкой для блюда. А таймер напомнит о следующем шаге.",
				mobileContent:
					"Повар открывает техкарту, чтобы свериться с рецептом и граммовкой для блюда. А таймер напомнит о следующем шаге.",
				mediaContent: (
					<StaticImage
						className={mediaContent.image2}
						src="./assets/kds-usage-2.png"
						alt="техкарта на экране повара"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				desktopContentWidth: 500,
				tabName: "Из кухни в зал",
				desktopTitle: "Готово! Можно выносить",
				desktopContent: (
					<p>
						Как только повар даёт отмашку, на терминале официанта меняется статус
						заказа. Звуковое уведомление подскажет, что&nbsp;пора забирать блюдо.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image3}
						src="./assets/kds-usage-3.webp"
						alt="уведомления на кассовом терминале"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		],
	}
}
